import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout.js'
import SEO from '../components/seo.js'
import Article from '../components/article.js'
import EmailForm from "../components/form";
import LazyLoad from 'react-lazyload';
import { Link } from 'gatsby'

 // const meta_title = prismicContent.node.data.meta_title.text || title;
 //    const og_image = prismicContent.node.data.og_image.url;
 //    const keywords = prismicContent.node.data.keywords.text;
 //    const meta_description = prismicContent.node.data.meta_description.text;

const IndexPage = props => (
  <Layout>
    <div className="page">
      <SEO
          title={`${props.data.allPrismicHomepage.edges[0].node.data.meta_title.text}`}
          keywords={[`${props.data.allPrismicHomepage.edges[0].node.data.keywords.text}`]}
          description={props.data.allPrismicHomepage.edges[0].node.data.meta_description.text}
          og_image={props.data.allPrismicHomepage.edges[0].node.data.og_image.url}
        />

      <section id="hero" className="hero-section text-gray-600 body-font lg:pb-8 rounded-b-lg">
            <div className="container mx-auto flex px-6 lg:pt-32 py-0 flex-col items-center pt-0" >
                <div
                    className="lg:px-12 mt-24 mb-14 lg:mt-14 flex flex-col lg:mb-22 items-center text-center">
                    <h1 className="lg:px-12 lg:text-5xl text-3xl mb-4 font-bold text-gray-200 leading-normal">{props.data.allPrismicHomepage.edges[0].node.data.hero_title.text}</h1>
                     <div
                        className="lg:px-64 mt-4 mb-8 text-gray-300 tracking-wide leading-normal"
                        dangerouslySetInnerHTML={{ __html: props.data.allPrismicHomepage.edges[0].node.data.hero_paragraph.html}}
                      />
                    {/*<p className="lg:px-64 mt-4 mb-8 text-gray-300 tracking-wide leading-normal text-lg">Subscribe to our newsletter to get latest news and tips on building products focused on Baltic region</p>*/}

                    <div className="flex w-full flex-col justify-start  lg:items-center lg:text-center">
                        <EmailForm/>

                    </div>
                </div>

            </div>
        </section>

            <section className="lg:pt-8 pb-10 bg-gray-900">
              <div className="content-header-container mx-auto px-6 lg:px-0">
              <h1 className="text-4xl font-bold mb-2 text-pink-600 pb-4">Featured Stuff
              </h1>
              {props.data.articles.edges.map(article => (
                  // <LazyLoad height={200}>
                    <Article uid={article.node.uid} data={article.node.data} />
                // </LazyLoad>
              ))}</div>
            </section>

        {/*<LazyLoad height={200}>*/}
            <section className="content-header-container mx-auto mt-0 lg:px-0 px-6">
                    <div className="rounded-lg text-gray-300 shadow-lg bg-gray-800 h-20 flex justify-center items-center flex-col">
                        <Link to={"/articles/"}>
                            <button className="hover:bg-pink-800 bg-pink-600 rounded py-1 px-3 text-sm">
                                View All
                            </button>
                        </Link>
                    </div>
                </section>
        {/*</LazyLoad>*/}

        {/*<section className="lg:pt-8 pb-10 bg-gray-900">*/}
        {/*    <LazyLoad height={200}>*/}
        {/*      <div className="content-header-container mx-auto px-6 lg:px-0">*/}
        {/*      <h1 className="text-4xl font-bold mb-2 text-pink-600 pb-4">Join our discord community</h1>*/}
        {/*          <iframe src="https://discordapp.com/widget?id=806556629230944277&theme=dark" width="350" height="500"*/}
        {/*                  allowTransparency="true" frameBorder="0"*/}
        {/*                  sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"></iframe>*/}

        {/*      </div>*/}
        {/*    </LazyLoad>*/}
        {/*    </section>*/}




    </div>
  </Layout>
)

export default IndexPage

export const IndexQuery = graphql`
query Articles {
  articles: allPrismicArticle(limit: 3, filter: {data: {featured: {eq: true}}}, sort: {fields: data___publish_date, order: DESC}) {
    edges {
      node {
        uid
        data {
          title {
            text
          }
          image {
            url
            alt
          }
          featured
          publish_date
          category
          featured_paragraph {
            html
          }
          author {
            text
          }
        }
      }
    }
  }
  allPrismicHomepage {
    edges {
      node {
        data {
          hero_paragraph {
            html
          }
          hero_title {
            text
          }
          keywords {
            text
          }
          meta_description {
            text
          }
          meta_title {
            text
          }
          og_image {
            alt
            url
          }
        }
      }
    }
  }
}

`